import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "bg-white my-8 border shadow rounded-md"
}
const _hoisted_3 = { class: "flex items-center justify-between px-5 mb-4" }
const _hoisted_4 = { class: "text-lg font-medium" }
const _hoisted_5 = { class: "flex py-4" }
const _hoisted_6 = { class: "flex flex-row items-center" }
const _hoisted_7 = { class: "flex flex-col ml-3" }
const _hoisted_8 = {
  "data-dusk": "journey-type",
  class: "font-medium text-gray-900"
}
const _hoisted_9 = {
  "data-dusk": "journey-laterality",
  class: "text-sm text-gray-600"
}
const _hoisted_10 = {
  key: 0,
  class: "text-sm text-gray-500"
}
const _hoisted_11 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_12 = { class: "text-sm font-medium" }
const _hoisted_13 = { class: "text-sm text-gray-500" }
const _hoisted_14 = { class: "flex flex-row items-center justify-end space-x-3" }
const _hoisted_15 = {
  key: 1,
  class: "w-full h-full flex flex-col justify-center items-center text-center p-30 space-y-6"
}
const _hoisted_16 = { class: "flex flex-col space-y-3" }
const _hoisted_17 = { class: "text-lg font-semibold leading-tight" }
const _hoisted_18 = { class: "text-gray-500" }
const _hoisted_19 = { class: "flex flex-row space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_IconGlyph = _resolveComponent("IconGlyph")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_MyopiaPrognosisModal = _resolveComponent("MyopiaPrognosisModal")!
  const _directive_feature_flagged = _resolveDirective("feature-flagged")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.journeys.length || _ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('custom.menicon.prescriptions.prescriptions')), 1),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                color: "clear",
                class: "text-secondary-600",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMyopiaPrognosisModal = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseIcon, {
                    name: "myopia",
                    class: "mr-3"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('custom.menicon.myopia-prognosis.title')), 1)
                ]),
                _: 1
              })), [
                [_directive_feature_flagged, _ctx.myopiaPrognosisFeatureFlag]
              ]),
              _createVNode(_component_BaseButton, {
                color: "ghost",
                class: "ml-4",
                onClick: _ctx.newJourney
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseIcon, {
                    name: "add",
                    class: "mr-4 text-lg"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('custom.menicon.prescriptions.new-initial-measurement')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _createVNode(_component_DataTable, {
            style: {"border-spacing":"0","border-collapse":"collapse !important"},
            class: _normalizeClass(["w-full", { 'border-b': _ctx.total && _ctx.total > _ctx.perPage }]),
            "custom-heading-classes": "text-sm text-gray-500 pb-2",
            "cell-style-classes": "border-t rounded-b-md",
            columns: [
          { name: 'type', label: _ctx.$t('custom.menicon.prescriptions.prescription') },
          {
            name: 'lastActivity.updated_at',
            label: _ctx.$t('platform.common.last-activity'),
            align: 'left',
            sortable: true
          },
          { name: 'empty', label: '', align: 'left' },
          { name: 'actions', label: '', align: 'right' }
        ],
            sort: _ctx.sort,
            rows: _ctx.journeys,
            onSort: _ctx.updateSort
          }, {
            "cell.type": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_IconGlyph, {
                  name: "bill-glyph-16",
                  color: row.ended_at ? 'gray' : 'primary',
                  size: "small"
                }, null, 8, ["color"]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(row.type || _ctx.$t('custom.menicon.prescriptions.no-product')), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(row.laterality ? _ctx.$t(`platform.eyes.${row.laterality}-eye`) : _ctx.$t('platform.eyes.both-eyes')), 1)
                ])
              ])
            ]),
            "cell.lastActivity.updated_at": _withCtx(({ row }) => [
              (row.ended_at)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    _createVNode(_component_MkBadge, {
                      color: "gray",
                      filled: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.lens-ordering.cancel.ended-at', { date: _ctx.formatDate(row.ended_at) })), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : (row.last_activity)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t(`custom.menicon.encounter.type.${row.last_activity.type}`)), 1),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.formatDate(row.last_activity.updated_at)), 1)
                    ]))
                  : _createCommentVNode("", true)
            ]),
            "cell.actions": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_ButtonLink, {
                  to: { name: 'prescription-details', params: { patientId: _ctx.patientId, journeyId: row.id } },
                  color: "ghost"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (_ctx.showWorkflows(row))
                  ? (_openBlock(), _createBlock(_component_BasePopover, {
                      key: 0,
                      "data-dusk": "encounter-menu",
                      position: "left"
                    }, {
                      button: _withCtx(() => [
                        _createVNode(_component_BaseButton, {
                          "data-dusk": "start-encounter",
                          "right-icon": "chevron-down"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.prescriptions.new-encounter')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      menu: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getEncounterTypes(row.type), (encounterType) => {
                          return (_openBlock(), _createBlock(_component_PopoverButton, {
                            key: encounterType.type,
                            style: _normalizeStyle(`order: ${encounterType.order}`),
                            onClick: ($event: any) => (_ctx.newEncounter(row.id, encounterType))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(`custom.menicon.encounter.type.${encounterType.type}`)), 1)
                            ]),
                            _: 2
                          }, 1032, ["style", "onClick"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["class", "columns", "sort", "rows", "onSort"]),
          (_ctx.total && _ctx.total > _ctx.perPage)
            ? (_openBlock(), _createBlock(_component_BasePagination, {
                key: 0,
                class: "border-none mt-0",
                "sm-pages-limit": 8,
                "model-value": _ctx.page,
                "items-name": _ctx.$t('custom.menicon.prescriptions.prescriptions').toLowerCase(),
                "page-size": _ctx.perPage,
                length: _ctx.total,
                "onUpdate:modelValue": _ctx.changePage
              }, null, 8, ["model-value", "items-name", "page-size", "length", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ]))
      : (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_IconGlyph, {
              name: "user-glyph-32",
              size: "large"
            }),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('custom.menicon.prescriptions.no-prescription')), 1),
              _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('custom.menicon.prescriptions.start-instruction')), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_BaseButton, {
                "left-icon": "add",
                onClick: _ctx.newJourney
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.prescriptions.new-initial-measurement')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                color: "ghost",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMyopiaPrognosisModal = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.myopia-prognosis.title')), 1)
                ]),
                _: 1
              })), [
                [_directive_feature_flagged, _ctx.myopiaPrognosisFeatureFlag]
              ])
            ])
          ]))
        : _createCommentVNode("", true),
    (_ctx.showMyopiaPrognosisModal)
      ? (_openBlock(), _createBlock(_component_MyopiaPrognosisModal, {
          key: 2,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showMyopiaPrognosisModal = false))
        }))
      : _createCommentVNode("", true)
  ]))
}