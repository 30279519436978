
import { Vue, Options } from 'vue-class-component';
import MyopiaPrognosisModal from '@/custom/menicon/components/MyopiaPrognosisModal.vue';
import {
  BaseButton,
  DataTable,
  BasePopover,
  PopoverButton,
  BasePagination,
  BaseIcon,
  IconGlyph
} from '@/lib/components';
import { PatientJourneyService, JourneyTypesService, JourneyEncounterService } from '@/services/api';
import { EncounterTypeName, EncounterType, JourneyType, Journey, PaginatedResponse } from '@/models';
import axios, { CancelTokenSource } from 'axios';
import { FEATURES } from '@/constants';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    }
  },
  components: {
    ButtonLink,
    BaseIcon,
    IconGlyph,
    BaseButton,
    DataTable,
    MyopiaPrognosisModal,
    BasePopover,
    PopoverButton,
    BasePagination
  }
})
export default class PrescriptionsPage extends Vue {
  patientId!: string;
  loading = true;
  error = false;
  showMyopiaPrognosisModal = false;
  myopiaPrognosisFeatureFlag = FEATURES.MYOPIA_PROGNOSIS;
  journeyService = new PatientJourneyService(this.patientId);
  journeys: Journey[] = [];
  journeyTypesService = new JourneyTypesService();
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  perPage = 10;
  total = 0;
  request: CancelTokenSource = axios.CancelToken.source();

  journeyTypes: JourneyType[] = [];

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || '-lastActivity.updated_at';
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  async created() {
    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.onRouteQueryChange();
      } else {
        unWatchRoute();
      }
    });
    await this.fetchData();
  }

  beforeUnmount() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchData() {
    try {
      this.progressStore.startProgress();
      await this.fetchJourneys();
      await this.fetchJourneyTypes();
      this.progressStore.finishProgress();
    } catch (e) {
      this.error = true;
      if (!axios.isCancel(e)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async fetchJourneys() {
    const response = (await this.journeyService.index({
      params: {
        include: 'lastActivity',
        sort: this.sort,
        page: this.page,
        per_page: this.perPage
      },
      cancelToken: this.request.token
    })) as PaginatedResponse<Journey[]>;
    this.journeys = response.data;
    this.total = response.meta.total;
  }

  async onRouteQueryChange() {
    try {
      this.progressStore.startProgress();
      await this.fetchJourneys();
      this.progressStore.finishProgress();
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.menicon.prescriptions.fetch-error')
        });
      }
    }
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async fetchJourneyTypes() {
    this.journeyTypes = (await this.journeyTypesService.index({ cancelToken: this.request.token })).data;
  }

  async updateSort(sort: string) {
    await this.$router.replace({
      path: this.$route.path,
      query: {
        sort
      }
    });
  }

  async newJourney() {
    try {
      const journey = (
        await this.journeyService.create({
          organisational_unit_id: this.organisationId
        })
      ).data;
      const encounter = (
        await new JourneyEncounterService(this.patientId, journey.id).create({
          type: EncounterTypeName.INITIAL_MEASUREMENT
        })
      ).data;
      await this.$router.push({
        name: 'patient-encounter',
        params: {
          patientId: this.patientId,
          journeyId: journey.id,
          encounterId: encounter.id
        }
      });
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.menicon.error.journey-create')
      });
    }
  }

  async newEncounter(journeyId: string, encounterType: EncounterType) {
    const encounter = (
      await new JourneyEncounterService(this.patientId, journeyId).create({
        type: encounterType.type
      })
    ).data;
    await this.$router.push({
      name: 'patient-encounter',
      params: {
        patientId: this.patientId,
        journeyId,
        encounterId: encounter.id
      }
    });
  }

  formatDate(isoDate: string) {
    return this.$d(new Date(isoDate), 'shortMonth');
  }

  getEncounterTypes(journeyType: string): EncounterType[] {
    return (
      this.journeyTypes
        .find((type: JourneyType) => type.type === journeyType)
        ?.encounter_types?.filter((type: EncounterType) => type.show) || ([] as EncounterType[])
    );
  }

  showWorkflows(journey: Journey): boolean {
    return !!(
      !journey.ended_at && this.getEncounterTypes(journey.type as string).flatMap((type) => type.available_forms).length
    );
  }
}
